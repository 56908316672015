import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { isMobile, isTablet } from './utils';
import autosize from 'autosize';

$('.form-control')
  .on('focus', function() { $(this).parent().addClass('focus'); })
  .on('blur', function () { $(this).parent().removeClass('focus'); })
  .on('input', function () { $(this).toggleClass('touched', Boolean($(this).val())); });

$('.file-wrapper').on('click', function() {
  $(this).find('input').click();
});

$('.file-wrapper input').on('click', function(e) {
  e.stopPropagation();
});

$('.file-wrapper input').on('change', function() {
  var filename = $(this).val();
  if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
  }
  $(this).siblings('label').text(filename ? filename : 'Attach a file');
});

$('.form-wrapper.select').on('click', function() {
  $(this).toggleClass('active');
})

$('.select-values li').on('click', function() {
  var value = $(this).text();
  const $inputWrapper = $(this).closest('.form-control');
  const $formWrapper = $(this.closest('.form-wrapper'));
  $inputWrapper.find('.current-value').text(value);
  $inputWrapper.find('input').val(value);
  $formWrapper.addClass('touched');
});

function isValidEmail(input) {
  if (input.attr('type') !== 'email') {
    return true;
  }

  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(input.val()).toLowerCase());
}

function isNotCompanyEmail(input) {
  if (input.attr('type') !== 'email') {
    return true;
  }

  var re = /^[\w-\._\+%]+@(live|hotmail|outlook|aol|yahoo|rocketmail|gmail|gmx\.com|mail.com|inbox.com|icloud|aim|yandex|zoho|mail)\./;
  return re.test(String(input.val()).toLowerCase());
}

function validateForm($form, checkPrivacyPolicy = false) {
  let isFormValid = true;
  $form.find('.form-control.required').each(function () {
    let $wrapper = $(this).closest('.form-wrapper');

    try {
      if (!$(this).val()) {
        throw new Error(`Please enter your ${$wrapper.find('label').text()}`);
      }

      if ($(this).attr('type') === 'email' && !isValidEmail($(this))) {
        throw new Error('Please enter a valid e-mail');
      } else if (($(this).attr('id') === 'audit-email') && isNotCompanyEmail($(this))) {
        throw new Error('Please enter a Work e-mail');
      }

      $wrapper.removeClass('error');
      $wrapper.find('.form-error').text('');

    } catch (err) {
      isFormValid = false;

      $wrapper.addClass('error');
      $wrapper.find('.form-error').text(err.message);
    }
  });

  if (checkPrivacyPolicy) {
    const $privacyCheckbox = $form.find('#contact-privacy-policy-accept');

    if (!$privacyCheckbox.is(':checked')) {
      $privacyCheckbox.siblings('.form-error').text('You must accept our Privacy Policy');
      $privacyCheckbox.parent().addClass('error');
      isFormValid = false;
    } else {
      $privacyCheckbox.siblings('.form-error').text('');
      $privacyCheckbox.parent().removeClass('error');
    }
  }

  return isFormValid;
}

export function setupForm($form, $overlay, checkPrivacyPolicy = false) {
  if (!$form) {
    return;
  }

  $form.find('input').on('change', function () {
    const isFormValid = validateForm($form, checkPrivacyPolicy);
  });

  $form.on('submit', function() {
    $form.find('input').blur();

    const isFormValid = validateForm($form, checkPrivacyPolicy);

    if (!isFormValid) {
      return false;
    }

    // Mockup for displaying thank you message
    setTimeout(() => {

      $overlay.addClass('active');
      if (isTablet()) {
        $overlay[0].scrollIntoView({
          behavior: 'smooth'
        });
      }
    }, 250);

    return true;
  });
}

export function setupRemoteForm($form, $overlay, checkPrivacyPolicy = false){
  if (!$form) {
    return;
  }

  $form.on('ajax:beforeSend', function(event) {
    return validateForm($form, checkPrivacyPolicy);
  });

  $form.on("ajax:success", (event) => {
    $overlay.addClass('active');
    if (isTablet()) {
      $overlay[0].scrollIntoView({
        behavior: 'smooth'
      });
    }
  });

  $form.on("ajax:error", (event) => {
    event.preventDefault();
    const [_data, _status, xhr] = event.detail;
    if($('#exception').length) {
      $('#exception').remove()
    }
    $form.find("input[type=submit]").before("<p id='exception' class='mb-0 error' style='color: #f5ba5b; display: none;'>\
                                               There are some troubles. Try again later.\
                                             </p>");
    $('#exception').show('slow');
  });
}

// Refactoring!!!
const $contactForm = $('#contact-form');
setupRemoteForm($contactForm, $contactForm.siblings('.thank-you-overlay'), true);

const $miniContactForm = $('#mini-contact-form');
setupForm($miniContactForm, $miniContactForm.siblings('.thank-you-overlay'), true);

$(document).ready(() => {
  $('#contact-comments').attr('rows', isMobile() ? 3 : 1);
  autosize($('#contact-comments'));
});
