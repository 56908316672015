import { isTablet } from "./utils";

$(document).ready(function () {
  function checkMediaQuery() {
    if (window.matchMedia("(min-width: 1600px)").matches) {
      $(".dropdown-menu-blog-content").css("padding-left", "1rem");
    } else {
      $(".dropdown-menu-blog-content").css("padding-left", "0");
    }
  }

  function createOffset() {
    var leftPaddings = $("#dropdown-container").offset().left;

    var offsetElementServices = $(".dropdown-offset-services");

    var buttonElementServices = $("#services").offset().left;
    offsetElementServices.width(buttonElementServices - leftPaddings);

    var offsetElementQualifications = $(".dropdown-offset-qualifications");

    var buttonElementQualifications = $("#qualifications").offset().left;
    offsetElementQualifications.width(
      buttonElementQualifications - leftPaddings
    );

    var offsetElementBlog = $(".dropdown-offset-blog");
    offsetElementBlog.width(buttonElementServices - leftPaddings);
    checkMediaQuery();
  }

  createOffset()

  $(window).on(
    "resize",
    debounce(function () {
      createOffset()
    }, 20)
  );

  function debounce(func, wait) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        func.apply(context, args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  var hideTimeout;

  function removeActiveArrow() {
    $("#services, #qualifications, #blog").each(function () {
      if ($(this).hasClass("active-arrow")) {
        $(this).removeClass("active-arrow");
      }

      if ($(this).hasClass("active-arrow-mobile")) {
        $(this).removeClass("active-arrow-mobile");
      }
    });
  }

  function hideAllTabletDropdowns() {
    $("ul[id^='mobile-']").addClass("display-not");
  }

  $("#cases a, #company a, #services a, #qualifications a, #blog a").on("ontouchstart touchstart click", function (event) {
    if (isTablet()) {
      event.stopPropagation();
      window.location.href = $(this).attr('href');
    }
  });

  $("#services, #qualifications, #blog").on(
    "mouseenter touchstart ontouchstart",
    function (event) {
      event.preventDefault();
      if (!isTablet()) {
        clearTimeout(hideTimeout);

        var target = $(this).attr("id");
        var dropdown = $("#dropdown-container ul.dropdown-menu-" + target);

        $("#dropdown-container").css("max-height", "500px");
        $("#navbar").addClass("active");
        dropdown
          .siblings()
          .removeClass("display-block")
          .addClass("display-not");
        dropdown.removeClass("display-not").addClass("display-block");

        $("#navbar")
          .stop()
          .animate({ height: dropdown.outerHeight() + ($('#navbar').hasClass('sps--abv') ? 100 : 70) }, 300);

        removeActiveArrow();
        $(this).addClass("active-arrow");
        $(".blur-overlay").fadeIn(150);
      }
    }
  );

  $(".arrow").on(
    "click touchstart",
    function (event) {
      if (isTablet()) {
        var target = $(this).parent().attr("id");
        var dropdown = $("ul#mobile-" + target);
        var mobileContactButton = $('#contact-button');
        var contactButton = $('#get-in-touch-btn');
        
        if (dropdown.hasClass("display-not")) {
          mobileContactButton.css('display', 'flex');
          contactButton.css('display', 'none');
          hideAllTabletDropdowns();
          dropdown.removeClass("display-not");
          removeActiveArrow();
          $(this).parent().addClass("active-arrow");
          $(this).parent().addClass("active-arrow-mobile");
        } else {
          dropdown.addClass("display-not");
          removeActiveArrow();
          mobileContactButton.css('display', 'none');
          contactButton.css('display', 'flex');
        }
      }
    }
  );

  $("#navbar, #dropdown-container").on("mouseleave", function () {
    if (!isTablet()) {
      if ($("#navbar").hasClass("active")) {
        hideTimeout = setTimeout(function () {
          $("#dropdown-container ul").animate(
            {
              opacity: 0,
            },
            300,
            function () {
              $(this)
                .css("opacity", "")
                .removeClass("display-block")
                .addClass("display-not");
              $("#navbar").removeClass("active");
            }
          );
          $("#dropdown-container").css("max-height", "auto");

          $("#navbar").stop().animate({ height: "4.3rem" }, 300);

          removeActiveArrow();
          $(".blur-overlay").fadeOut(300);
        }, 300);
      }
    } else {
      hideAllTabletDropdowns();
      removeActiveArrow();
    }
  });

  $("#navbar, #dropdown-container").on(
    "mouseenter touchstart",
    function (event) {
      event.preventDefault();

      if (!isTablet()) {
        clearTimeout(hideTimeout);
      }
    }
  );

  $("#navbar-mobile-header a, #get-in-touch-btn, #nav-logo").on("ontouchstart touchstart click", function (event) {
    event.stopPropagation();
    window.location.href = $(this).attr('href');
  });
});
