export function isTablet() {
  return window.innerWidth < 1024;
}

export function isSmallerMobile() {
  return window.innerWidth < 576
}

export function isTabletState() {
  return window.innerWidth > 576 && window.innerWidth < 1024
}

export function isMobile() {
  return window.innerWidth < 768;
}

export function isDesktop() {
  return window.innerWidth >= 1280;
}

export function isTouchDevice() {
  return 'ontouchstart' in window        // works on most browsers
    || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

export function isSafari() {
  return navigator.vendor.match(/apple/i);
}

export function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

export function hasScrollbar() {
  return window.innerWidth > document.documentElement.clientWidth;
}

export function setupPageLoadCallback(callback) {
  window.addEventListener('DOMContentLoaded', callback);
}

export function setupContentLoadCallback(callback) {
  window.addEventListener('load', callback);
}

let hasOrientationChanged = false;

export function setupResponsiveCallback(callback, forceCheckResize = false) {
  window.addEventListener('DOMContentLoaded', callback);
  window.addEventListener('orientationchange', () => {
    hasOrientationChanged = true;
    callback();
    setTimeout(() => hasOrientationChanged = false, 250);
  });
  window.addEventListener('resize', () => {
    if (hasOrientationChanged) {
      callback();
    }
  });

  if (isDesktop() || forceCheckResize) {
    window.addEventListener('resize', callback);
  }
}
