import $ from 'jquery';

$(document).ready(function() {
  const emojis = $('.raitings-emoji');
  const heading = $('.raitings-heading');
  const stats = $('.raitings-stats');
  const block = $('.raitings-block');

  emojis.click(function() {
    if (!$('.raitings-emoji-inactive').length) {
      emojis.addClass('raitings-emoji-inactive').css('transform', 'scale(1)');
      stats.hide();
      heading.text("Thank you for your review!");
      block.addClass('raitings-block-done');
      $(this).removeClass('raitings-emoji-inactive').css('transform', 'scale(1.1)');
    }
  });

  if ($('.raitings-emoji-inactive').length) {
    emojis.each(function() {
      if(!$(this).hasClass('raitings-emoji-inactive')) {
        $(this).css('transform', 'scale(1.1)');
      }
    });
  }

  $(document).on('click', '#feedback-buttons .btn', function() {
    if(!$('#voteless').length || !$('.raitings-emoji-inactive').length) {
      const $button = $(this);
      $('#feedback-buttons input').val($button.data('value'));
    }
  });
});
