import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import smoothscroll from 'smoothscroll-polyfill';

import { isMobile, isTablet, setupResponsiveCallback, setupContentLoadCallback } from './utils';
import { checkSticky } from './sticky';

import './chatra-visability';
import './header';

import LazyLoad from "vanilla-lazyload";

function reflowMobileMenu() {
  const vh = window.innerHeight * 0.01;
  var element = document.getElementById('navbar-menu')
  if(element){
    element.style.setProperty('--vh', `${vh}px`);
  }
}

function setupFooter() {
  const hasFixedFooter = window.innerHeight >= 768 && window.innerWidth >= 768;
  $('main').css({
    marginBottom: `${hasFixedFooter ? $('footer').height() : 0}px`
  });
}

function reflowVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

smoothscroll.polyfill();

$(document).ready(() => {
  ScrollPosStyler.init({
    scrollOffsetY: isMobile() ? 50 : 70,
  });

  new WOW({ mobile: false, offset: 50 }).init();
});

setupResponsiveCallback(() => {
  reflowVh();
  setupFooter();
  checkSticky();
});

setupResponsiveCallback(() => {
  reflowMobileMenu();
}, true);

setupContentLoadCallback(() => {
  checkSticky();
})

// Fix Safari resetting transform on hover
$('.link-card, .stack-item, .service-item').hover(function () {
  $(this)
    .removeClass('wow fadeInUpSmall zoomInSmall')
    .css('animation-delay', '')
    .css('animation-name', '');
});

$('.navbar-toggler').on('touchstart click', () => {
  if ($('#navbar-menu').hasClass('active')) {
    enablePageScroll();
  } else {
    disablePageScroll();
  }
  $('#navbar-menu').toggleClass('active');
});

$('.scroll-down').on('click', () => {
  const heroHeight = document.querySelector('.hero-section').offsetHeight;
  const navbarHeight = document.querySelector('header').offsetHeight;
  window.scroll({
    top: isTablet() ? heroHeight - (navbarHeight / 2) : heroHeight,
    behavior: 'smooth'
  });
});

function addAjaxListenerForLazyLoad() {
  const links = document.querySelectorAll("a[data-remote]");
  links.forEach((element) => {
    if (element.getAttribute('ajax:success:listener') !== 'true'){
      element.addEventListener("ajax:success", () => {
        window.lazyLoadInstance.update();
        window.lazyLoadInstanceBg.update();
        addAjaxListenerForLazyLoad()
      });
      element.setAttribute('ajax:success:listener', 'true');
    }
  });
}

window.addEventListener("load", () => {
  addAjaxListenerForLazyLoad()
})

function init() {
  window.lazyLoadInstance = new LazyLoad({
    elements_selector: "img[data-src]",
    load_delay: 100
  });
  window.lazyLoadInstanceBg = new LazyLoad({
    elements_selector: "section[data-bg-multi]",
    load_delay: 100
  });
}

window.onload = init;
