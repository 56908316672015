var scrollline = document.querySelector(".scroll-line");

function fillscrollline() {
  var windowHeight = window.innerHeight;
  var targetHeight = 0;

  var children = document.body.children;
  for(var i = 0; i < children.length; i++) {
    var child = children[i];
    if(child.id === 'post-content') {
      break;
    }
    targetHeight += Math.floor(child.offsetHeight);
  }

  var fullHeight = document.documentElement.scrollHeight;
  var scrolled = window.scrollY;
  var percentScrolled = (scrolled / (fullHeight - windowHeight)) * 100;

  scrollline.style.width = percentScrolled + "%";
}

window.addEventListener("scroll", fillscrollline);
