import './scripts/main';
import './scripts/swipers';
import './scripts/share';
import './scripts/raitings';
import './scripts/scroll-progress';
import { setupRemoteForm } from './scripts/forms';
import { setupFaqBlock } from './scripts/faq_block';
require("@rails/ujs").start()

const $form = $('#blog-promo-form');
setupRemoteForm($form, $('#blog-promo-form-overlay'));
setupFaqBlock('.faq-block-outer');
setClickedEmoji();


$(document).ready(function() {
  var h2 = $('#conclusion-section > div > div > div.col-lg-7 > h2')[0].outerHTML
  $('#conclusion-section > .container').prepend(`<div class='col-lg-12>'>${h2}</div>`)
  $('#conclusion-section > div > div > div.col-lg-7 > h2').remove()
})

