$(function() {
  return window.addEventListener('message', function(event) {
    var e, message;
    message = void 0;
    try {
      message = JSON.parse(event.data);
    } catch (_error) {
      e = _error;
      return;
    }
    if (message.type === 'collapseWindow') {
      $("button.chatra-square").show();
    } else if (message.type === 'expandWindow') {
      $("button.chatra-square").hide();
    }
  });
});
