import Swiper from "swiper";
import {
  isDesktop,
  isMobile,
  isTablet,
  isTabletState,
  isSmallerMobile,
  setupResponsiveCallback,
} from "./utils";

import "swiper/css/swiper.min.css";
import "stylesheets/front/components/swiper.scss";

const getSwiperParams = (params) => ({
  slidesPerView: 1,
  spaceBetween: isDesktop() ? 32 : 20,
  pagination: {
    el: ".swiper-pagination",
  },
  preventClicks: false,
  watchOverflow: true,
  initialSlide: 0,
  loop: false,
  ...(typeof params === "function" ? params() : params),
});

function getDesktopPagination(enable = isDesktop()) {
  if (!enable) {
    return {};
  }

  return {
    pagination: {
      el: ".swiper-counter",
      type: "custom",
      renderCustom: (swiper, current, total) =>
        `<span class="current-counter">${current}</span>` +
        '<span class="counter-splitter"></span>' +
        `<span class="total-counter">${total}</span>`,
    },
  };
}

const blogSwiper = createSwiper("#blog-swiper", {
  preventClicks: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    992: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
  },
});

const getTestimonalsSwiperParams = () => ({
  navigation: {
    nextEl: ".swiper-control-next",
    prevEl: ".swiper-control-prev",
  },
  ...getDesktopPagination(!isTablet()),
});

export function createSwiper(selector, params) {
  return new Swiper(selector, getSwiperParams(params));
}

let testimonialsSwiper = null;
setupResponsiveCallback(() => {
  if (!testimonialsSwiper) {
    testimonialsSwiper = createSwiper(
      "#testimonials-swiper",
      getTestimonalsSwiperParams
    );
    return;
  }

  const currentPagination = testimonialsSwiper.params.pagination;
  const newPagination = getDesktopPagination();
  if (
    (currentPagination && !newPagination.pagination) ||
    (!currentPagination && !!newPagination.pagination)
  ) {
    testimonialsSwiper.destroy();
    testimonialsSwiper = createSwiper(
      "#testimonials-swiper",
      getTestimonalsSwiperParams
    );
  }
});

let servicesSwiper = null;
function setupServicesSwiper() {
  const $container = $("#services-swiper");
  if (!$container) {
    return;
  }

  const $wrapper = $container.find(".services-list");

  $container.toggleClass("swiper-container", isMobile());
  $wrapper.toggleClass("swiper-wrapper", isMobile());
  $container.find(".service-item").toggleClass("swiper-slide", isMobile());

  if (isMobile()) {
    if (!servicesSwiper) {
      servicesSwiper = createSwiper("#services-swiper", { spaceBetween: 0 });
    }
  } else {
    if (servicesSwiper !== null) {
      servicesSwiper.destroy();
      servicesSwiper = null;
    }
  }
}
setupResponsiveCallback(setupServicesSwiper);

export function setupCardDeckSwiperForMobile(selector) {
  let swiper = null;

  function setupSwiper() {
    const $container = $(selector);
    const $wrapper = $container.find(".card-deck");

    $container.toggleClass("swiper-container", isMobile());
    $wrapper.toggleClass("swiper-wrapper", isMobile());
    $container.find(".card").toggleClass("swiper-slide", isMobile());

    if (isMobile()) {
      if (!swiper) {
        swiper = createSwiper(selector, { spaceBetween: 0 });
      }
    } else {
      if (swiper !== null) {
        swiper.destroy();
        swiper = null;
      }
    }
  }

  setupResponsiveCallback(setupSwiper);
}

export function setupCardDeckSwiperForSmallerMobile(selector) {
  let swiper = null;

  function setupSwiper() {
    const $container = $(selector);
    const $wrapper = $container.find(".card-deck");

    $container.toggleClass("swiper-container", isSmallerMobile());
    $wrapper.toggleClass("swiper-wrapper", isSmallerMobile());
    $container.find(".card").toggleClass("swiper-slide", isSmallerMobile());

    if (isSmallerMobile()) {
      if (!swiper) {
        swiper = createSwiper(selector, { spaceBetween: 0 });
      }
    } else {
      if (swiper !== null) {
        swiper.destroy();
        swiper = null;
      }
    }
  }

  setupResponsiveCallback(setupSwiper);
}

export function setupCardDeckSwiperForDesktop(selector) {
  let swiper = null;

  function setupSwiper() {
    const isMobileView = isSmallerMobile();
    const isTabletView = isTabletState();
    const isDesktopView = isDesktop();

    $(".card-deck-swiper-blog").css({ display: isMobileView || isTabletView ? "none" : "block" });
    $(".card-deck-swiper-blog-tablet").css({ display: !isMobileView && isTabletView ? "block" : "none" });
    $(".card-deck-swiper-blog-mobile").each(function() {
      const $this = $(this);
      if (!$this.attr('id') || !$this.attr('id').includes('author')) {
          $this.css({ display: isMobileView && !isTabletView ? "block" : "none" });
      }
    });

    let currentSelector 

    if (isMobileView && !isTabletView) {
      currentSelector = `${selector}_mobile`
    } else if (isTabletView && !isMobileView) {
      currentSelector = `${selector}_tablet`
    } else {
      currentSelector = selector
    }

    const $container = $(currentSelector);
    $container.addClass("swiper-container");

    const $wrapper = $container.find(isMobileView && !isTabletView ? ".card-deck-mobile" : ".card-deck");
    $wrapper.addClass("swiper-wrapper");

    swiper = $container[0].swiper;
    if (swiper) {
      $container.find(".swiper-slide").removeClass("swiper-slide");
      swiper.destroy();
      swiper = null;
    }

    $container.find(isMobileView && !isTabletView ? ".card" : ".card-deck-group").addClass("swiper-slide");
    swiper = createSwiper(currentSelector, {
      spaceBetween: 0,
      watchOverflow: true,
      resizeReInit: true,
      pagination: { el: ".swiper-pagination", clickable: true },
      navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },
    });
  }

  setupResponsiveCallback(setupSwiper);
}
