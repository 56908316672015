import { isMobile } from "./utils";

const handleItemClick = function() {
  const parentItem = $(this).parent();

  if (parentItem.hasClass('active')) {
    return;
  }

  $('.faq-item.active > div').slideToggle(300);
  $('.faq-item.active').removeClass('active');

  parentItem.addClass('active');
  $(this).next().slideToggle(300);
};

export const resetFaqBlock = ($container) => {
  $container.removeAttr('style');
  $container.find('.faq-item > div').removeAttr('style');
  $container.find('.faq-item').removeClass('active');
  $container.find('.faq-item:first-child').addClass('active');
  $container.find('.faq-item h3').off('click', handleItemClick);
};

export const setupFaqBlock = (selector = '.faq-block') => {
  const $container = $(selector);

  if (!$container) {
    return;
  }

  // Reset container
  resetFaqBlock($container);

  if (!isMobile()) {
    const fixedHeight = $container.find('.faq-item').get()
      .reduce((height, item) => height + $(item).outerHeight(true), 0);
      
    const maxParagraphHeight = $container.find('.faq-item > div').get()
      .reduce((maxHeight, div) => Math.max(maxHeight, $(div).outerHeight()), 0);

    $container.css('height', `${fixedHeight + maxParagraphHeight}px`);
  }

  $container.find('.faq-item.active > div').slideToggle(300);
  $container.find('.faq-item h3').on('click', handleItemClick);
};
