$("#twitter_share").on("click", function(e){
  e.preventDefault();
  var url = "https://twitter.com/intent/tweet?url=" + $(this).data("url");
  url += '&text=' + $(this).data("title");
  url += '&via=agentestudio'
  openSocialPopup(600, 450, url, "sh_linkedin");
  incrementCounter($(this).data("increment-share"))
});

$("#facebook_share").on("click", function(e){
  e.preventDefault();
  var url = "https://www.facebook.com/sharer/sharer.php?u=" + $(this).data("url");
  openSocialPopup(600, 450, url, "sh_facebook");
  incrementCounter($(this).data("increment-share"))
});

$("#linkedin_share").on("click", function(e){
  e.preventDefault();
  var url = "https://www.linkedin.com/shareArticle?mini=true&summary=&source=&url=" + $(this).data("url");
  openSocialPopup(600, 450, url, "sh_linkedin");
  incrementCounter($(this).data("increment-share"))
});

$("#pinterest_share").on("click", function(e){
  e.preventDefault();
  var url = "http://pinterest.com/pin/create/button/?url=" + $(this).data("url");
  url += 'description=' + $(this).data("title");
  url += '&media=' + $(this).data("image");
  openSocialPopup(600, 450, url, "sh_pinterest");
  incrementCounter($(this).data("increment-share"))
});

function openSocialPopup(width, height, url, title) {
  const y = window.top.outerHeight / 2 + window.top.screenY - ( height / 2);
  const x = window.top.outerWidth / 2 + window.top.screenX - ( width / 2);
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+width+', height='+height+', top='+y+', left='+x);
}
