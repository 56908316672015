import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import stickybits from 'stickybits';

let stickyInstances = {};
export function checkSticky() {
  $('.sticky').each(function () {
    setupSticky($(this));
  });
}

function setupSticky($el) {
  const id = $el.attr('id');
  const stickyEnabled = window.innerWidth >= $el.attr('data-sticky-for')
    && window.innerHeight >= ($el.attr('data-min-height') || 500);

  if (!stickyEnabled) {
    destroySticky($el);
    return;
  }

  if ($el.attr('data-width-parent')) {
    $el.css({ width: $el.parent().width() });
  } else {
    $el.css({ width: $el.width() });
  }

  if (!stickyInstances[id]) {
    stickyInstances[id] = stickybits(`#${id}`, {
      useFixed: true,
      stickyBitStickyOffset: Number($el.attr('data-margin-top')),
    });
  } else {
    stickyInstances[id].update();
  }
}

function destroySticky($el) {
  const id = $el.attr('id');
  if (!stickyInstances[id]) {
    return;
  }

  $el.css({ width: null });
  stickyInstances[id].cleanup();
  stickyInstances[id] = null;
}
